import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { DsBoxModule, DsButtonModule, DsModalModule } from '@bmw-ds/components';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    RouterLink,
    TranslateModule,
    RouterLink,
    DsBoxModule,
    DsModalModule,
    DsButtonModule
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  currentYear: number;
  modalIsOpen = false;
  constructor() {
    this.currentYear = new Date().getFullYear();
  }

  closeModal() {
    this.modalIsOpen = false;
  }
}
