<div class="neo-ds BMW grp-header__link">
  <select
    class="neo-input-dropdown language"
    [ngModel]="selectedLanguage"
    (ngModelChange)="handleLangChange($event)"
  >
    <option *ngFor="let lang of localeOptions" [value]="lang.id">
      {{ lang.label }}
    </option>
  </select>
</div>
