import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IntegrationService } from '../../../services/integration/integration.service';
import { AssignmentDto } from '../../../dtos/AssignmentDto';
import { AssignmentActionType } from '../../../dtos/AssignmentActionType';
import { ViewSource } from '../../../dtos/ViewSource';
import { ShowDetailsWrapper } from '../../../dtos/ShowDetailsWrapper';
import { DataService } from '../../../services/data.service';
import '@neo-ds/components/button';
import '@neo-ds/components/tooltip';
import { AssignmentStatus } from '../../../dtos/AssignmentStatus';

@Component({
  selector: 'app-bmw-assignment-card[assignment]',
  templateUrl: './bmw-assignment-card.component.html',
  styleUrls: ['./bmw-assignment-card.component.scss']
})
export class BmwAssignmentCardComponent {
  constructor(private service: IntegrationService,
              private dataService: DataService) {}

  @Input()
  assignment: AssignmentDto | undefined;
  action: AssignmentActionType | undefined;

  @Output()
  showPackageView = new EventEmitter<ShowDetailsWrapper>();

  modalIsOpen = false;

  // calling this will open the modal
  openMethod(action: string) {
    if ('WITHDRAW' === action) this.action = AssignmentActionType.WITHDRAW;
    else if ('REJECT' === action) this.action = AssignmentActionType.REJECT;
    else if ('APPROVE' === action) this.action = AssignmentActionType.APPROVE;
    this.modalIsOpen = true;
  }

  // calling this will dismiss the modal
  closeModal() {
    this.modalIsOpen = false;
  }

  changeAssignment() {
    if (this.assignment && this.assignment.gcid) {
      const gcid = this.assignment.gcid;

      let status: AssignmentStatus;
      switch (this.action) {
        case AssignmentActionType.APPROVE:
          status = AssignmentStatus.ACCEPTED;
          break;
        case AssignmentActionType.REJECT:
          status = AssignmentStatus.REJECTED;
          break;
        case AssignmentActionType.WITHDRAW:
          status = AssignmentStatus.REVOKED_BY_CUSTOMER;
          break;
        default:
          throw new Error('Unsupported action: ' + this.action);
      }

      this.service
        .changeAssignmentStatus(this.assignment.vin, gcid, status)
        .subscribe((_ignore) => {
          this.action = undefined;
          this.service.getAssignment(gcid).subscribe((val) => {
            if (val) {
              this.assignment = val;
              this.service.redirectBackTo(
                !this.assignment.assignmentUuid ? this.assignment.id : this.assignment.assignmentUuid,
                this.assignment.status,
                ViewSource.ASSIGNMENT
              );
            }
          });
          this.closeModal();
        });
    }
  }

  get showAcceptOrRejectButton() {
    return ['REQUESTED'].includes(this.assignment?.status || '');
  }

  get showRevoke() {
    return ['ACCEPTED', 'ACTIVE'].includes(this.assignment?.status || '');
  }

  get disableButtons(){
    if(this.assignment){
      return this.assignment.gcid !== this.dataService.gcId;
    }
    return false;
  }

  showPackageDetailView() {
    const wrapper: ShowDetailsWrapper = {
      source: ViewSource.ASSIGNMENT,
      shouldShow: true
    };
    this.showPackageView.emit(wrapper);
  }
}
