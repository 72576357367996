<div class="theme-bmw assignment-card neo-ds BMW">
  <div class="assignment-card-header content-headline-s">
    {{ assignment?.companyName }}
  </div>
  <div class="assignment-table">
    <div class="row">
      <div class="col">{{ 'assignment.card.requestOf' | translate }}</div>
      <div class="col">{{ assignment?.createdAt | date: 'YYYY-MM-d' }}</div>
    </div>
    <div class="row">
      <div class="col">{{ 'assignment.card.status' | translate }}</div>
      <div class="col" style="color: #1976d2; font-weight: bold">
        {{ assignment?.status }}
      </div>
    </div>
    <div class="row">
      <div class="col">{{ 'assignment.card.statusChanged' | translate }}</div>
      <div class="col">{{ assignment?.updatedAt | date: 'YYYY-MM-d' }}</div>
    </div>
    <div class="row" (click)="showPackageDetailView()" style="cursor: pointer">
      <div class="col">{{ 'assignment.card.dataPackage' | translate }}</div>
      <div class="col" style="font-weight: bold">
        <i class="wi wi-arrow-chevron-right"></i>
        <span class="truncate" id="tooltip">{{
          'assignment.package-details.request.' +
            assignment?.serviceType +
            '.name' | translate
        }}</span>
        <neo-tooltip
          id="tooltipAssignment"
          brand="BMW"
          heading="{{
            'assignment.package-details.request.' +
              assignment?.serviceType +
              '.name' | translate
          }}"
          trigger="hover, click"
          position="auto"
          target="tooltip"
          variant="tooltip"
        >
        </neo-tooltip>
      </div>
    </div>
  </div>
  <div
    class="neo-ds BMW assignment-buttons"
    *ngIf="showRevoke || showAcceptOrRejectButton"
  >
    <button
      (click)="openMethod('APPROVE')"
      *ngIf="showAcceptOrRejectButton"
      [ds-modal-trigger-for]="tpl"
      [(isModalOpen)]="modalIsOpen"
      [disabled]="disableButtons"
      class="neo-primary-btn"
    >
      {{ 'buttons.general.accept' | translate }}
    </button>
    <button
      (click)="openMethod('REJECT')"
      *ngIf="showAcceptOrRejectButton"
      [ds-modal-trigger-for]="tpl"
      [(isModalOpen)]="modalIsOpen"
      [disabled]="disableButtons"
      class="neo-tertiary-btn"
    >
      {{ 'buttons.general.reject' | translate }}
    </button>
    <button
      (click)="openMethod('WITHDRAW')"
      *ngIf="showRevoke"
      [ds-modal-trigger-for]="tpl"
      [(isModalOpen)]="modalIsOpen"
      [disabled]="disableButtons"
      class="neo-secondary-btn"
    >
      {{ 'buttons.general.revoke' | translate }}
    </button>
  </div>

  <ds-modal #tpl class="dialog-footer">
    <ds-box-header>{{
      'assignment.card.dialog-title' | translate
    }}</ds-box-header>
    <ds-box-content
      ><span *ngIf="action === 'WITHDRAW'">{{
        'assignment.card.revoke-content' | translate
      }}</span></ds-box-content
    >
    <ds-box-content
      ><span *ngIf="action === 'APPROVE'">{{
        'assignment.card.approve-content' | translate
      }}</span></ds-box-content
    >
    <ds-box-content
      ><span *ngIf="action === 'REJECT'">{{
        'assignment.card.reject-content' | translate
      }}</span></ds-box-content
    >

    <ds-box-footer>
      <button
        ds-button
        (click)="changeAssignment()"
        class="assignment-dialog-button"
      >
        <span *ngIf="action === 'WITHDRAW'">{{
          'buttons.general.revoke' | translate
        }}</span>
        <span *ngIf="action === 'REJECT'">{{
          'buttons.general.reject' | translate
        }}</span>
        <span *ngIf="action === 'APPROVE'">{{
          'buttons.general.accept' | translate
        }}</span>
      </button>
      <button ds-button (click)="closeModal()" class="assignment-dialog-button">
        {{ 'buttons.general.back' | translate }}
      </button>
    </ds-box-footer>
  </ds-modal>
</div>
