<div class="container rectangle-top">
  <div class="page-wrapper">
    <div class="vehicle-name section-headline teaser" *ngIf="!errorMsg">
      {{ vehicleData?.modelName }}
    </div>
    <div class="vin teaser" *ngIf="!errorMsg">
      {{ 'vehicle.details.vin' | translate }}
      {{ vehicleData?.vin }}
    </div>
  </div>
</div>

<div class="container rectangle theme-group">
  <div>
    <neo-loader
      color="PRIMARY"
      size="50"
      brand="vehicleData?.brand"
      center=""
      *ngIf="!vehicleData && !errorMsg"
    >
    </neo-loader>
  </div>
  <div class="page-wrapper container">
    <div *ngIf="vehicleData || errorMsg">
      <div class="header-text">MANAGE YOUR ACCESS</div>
      <div class="header-text-sub">
        <span *ngIf="showDetail">Access permission details</span>
      </div>
      <p *ngIf="errorMsg" [innerHTML]="errorMsg" style="padding: 15px"></p>
      <div *ngIf="imageUrl && !errorMsg" class="vehicle-image">
        <img src="{{ imageUrl.url }}" alt="Vehicle Image" />
      </div>
    </div>
  </div>
</div>
