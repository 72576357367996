<div class="app-group-header app-group-main-navigation">
  <div class="component__fullwidth__inner header__wrapper container">
    <div class="logo_menu">
      <img class="header__logo" src="assets/images/mini-logo.png" alt="Logo" />
    </div>
    <div class="header__servicebar">
      <app-language
        *ngIf="!environment.isUSHub"
        (menuToggled)="toggleMenu($event)"
      ></app-language>
    </div>
  </div>
</div>
