<div
  [class]="
    'container neo-ds ' +
    (brand !== 'GROUP' ? brand : 'BMW') +
    ' theme-' +
    theme
  "
>
  <div class="row">
    <div class="here--you-can-find-d">
      {{
        'Below are details about select BMW CarData access requested by {COMPANY_NAME} and how they want to use your CarData elements. If you have any question consult the applicant directly.'
          | translate
          | replace: '{COMPANY_NAME}' : clearanceInfo?.companyName
      }}
    </div>
  </div>

  <div class="row">
    <div class="personal-message-title">
      Personal message for the request with intended use
    </div>
    <div class="personal-message-content">
      {{ clearanceInfo?.clearanceRequestMessage }}
    </div>
  </div>

  <div class="row main-font">
    <div class="applicant-bmw-ag---c">
      <div class="personal-message-title">
        {{ 'clearance.package-details.applicant-info.title' | translate }}
      </div>
      <div>{{ clearanceInfo?.companyName }}</div>
      <div>{{ clearanceInfo?.companyAddress }}</div>
    </div>
  </div>

  <div class="row main-font">
    <div class="personal-message-title">
      {{
        'clearance.package-details.applicant-info.contact-details.title'
          | translate
      }}
    </div>
    <div>{{ clearanceInfo?.contactName }}</div>
    <div>{{ clearanceInfo?.contactPhone }}</div>
    <div>{{ clearanceInfo?.contactEmail }}</div>
  </div>

  <div class="row main-font" style="padding: 0 !important">
    <div class="please-note--the-app">
      <b>Please note:</b> The applicant of the access request is responsible for
      the specified information. BMW does not assume responsibility for the
      correctness of the information provided.
    </div>
  </div>
</div>

<div
  [class]="
    'container neo-ds ' +
    (brand !== 'GROUP' ? brand : 'BMW') +
    ' theme-' +
    theme
  "
>
  <div class="back" (click)="hidePackageDetailView()">
    <span>Back To Manage Access Permissions</span>
    @if (brand !== 'MINI') {
      <i class="wi wi-arrow-chevron-right"></i>
    } @else {
      <i class="wi wi-angle-right"></i>
    }
  </div>
</div>
<div
  [class]="
    'container neo-ds ' +
    (brand !== 'GROUP' ? brand : 'BMW') +
    ' theme-' +
    theme
  "
  style="background-color: #ffffff !important; width: 98% !important"
>
  <div class="main-font" style="padding: 20px; font-size: x-large">
    Details about the request
  </div>
  <div class="container main-font" style="padding: 20px">
    <div class="row" style="border: unset">
      <div class="col-2 tkeys-header">Element ID</div>
      <div class="col-5 tkeys-header">CarData Element</div>
      <div class="col-5 tkeys-header">Description</div>
    </div>
    <div class="row tkeys-row" *ngFor="let tkey of keysFiltered; let i = index">
      <div class="col-2 align-self-center">
        <span>{{ i + 1 }}</span>
      </div>
      <div class="col-5 align-self-center">{{ tkey.name }}</div>
      <div class="col-5 align-self-center" [innerHTML]="tkey.description"></div>
    </div>
  </div>
</div>
<div
  [class]="
    'container neo-ds ' +
    (brand !== 'GROUP' ? brand : 'BMW') +
    ' theme-' +
    theme
  "
>
  <div class="showmore" (click)="expandDetails()" *ngIf="!expanded">
    @if (brand !== 'MINI') {
      <i class="wi wi-arrow-chevron-down"></i>
    } @else {
      <i class="wi wi-angle-down"></i>
    }
    <span>{{ 'buttons.general.show-more' | translate }}</span>
  </div>
  <div class="showmore" (click)="collapseDetails()" *ngIf="expanded">
    @if (brand !== 'MINI') {
      <i class="wi wi-arrow-chevron-up"></i>
    } @else {
      <i class="wi wi-angle-up"></i>
    }
    <span>{{ 'buttons.general.collapse' | translate }}</span>
  </div>
</div>
