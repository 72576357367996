<div class="wrapper">
  <div class="horizontal-menu container">
    <div class="copyright">© 2022 - {{ currentYear }} BMW Group</div>
    <div class="legal">
      <a [ds-modal-trigger-for]="tpl" [isModalOpen]="modalIsOpen">{{
        'Terms and conditions' | translate
      }}</a>
    </div>
  </div>
</div>

<ds-modal #tpl [dimensionsConfig]="{ width: '600px' }" class="theme-bmw">
  <ds-box-header class="theme-bmw">{{
    'imprint.title' | translate
  }}</ds-box-header>
  <ds-box-content class="theme-bmw">
    <span>{{ 'imprint.1' | translate }}</span
    ><br />
    <span>{{ 'imprint.2' | translate }}</span
    ><br />
    <span>{{ 'imprint.3' | translate }}</span
    ><br />
    <span>{{ 'imprint.4' | translate }}</span
    ><br />
    <span>{{ 'imprint.5' | translate }}</span
    ><br />
    <span [innerHtml]="'imprint.6' | translate"></span><br />
    <span>{{ 'imprint.7' | translate }}</span
    ><br />
    <span>{{ 'imprint.8' | translate }}</span
    ><br />
    <span>{{ 'imprint.9' | translate }}</span
    ><br />
    <span>{{ 'imprint.10' | translate }}</span
    ><br />
  </ds-box-content>
</ds-modal>
