import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuService } from '../../../../../services/menu/menu.service';
import { EnvironmentConfigService } from '../../../../environment-config/environment-config.service';
import { MenuItem } from '../../../../models/menu-item';

@Component({
  selector: 'app-group-header',
  templateUrl: './group-header.component.html',
  styleUrls: ['./group-header.component.scss']
})
export class GroupHeaderComponent implements OnDestroy, OnInit {
  @Output()
  menuToggled = new EventEmitter<boolean>();
  @Output()
  closeMenu = new EventEmitter<boolean>();
  menuState: boolean = false;
  search: boolean = false;
  private subscriptions: Subscription[] = [];
  @Output()
  menuSelected = new EventEmitter<boolean>();
  @ViewChild('menu', { static: false })
  menu: any;
  @ViewChild('list', { static: false })
  list: any;
  menuItems: MenuItem[];
  delta = 20;
  lastScrollTop = 0;
  collapse = false;
  homeSelected = true;

  constructor(
    public menuService: MenuService,
    public environment: EnvironmentConfigService
  ) {
    this.menuItems = this.menuService.getMenuItems({ skipInHeader: true });
    this.subscriptions.push(
      this.menuService.urlChange.subscribe(() => {
        this.setActiveParent();
        this.menuState = false;
      })
    );
    this.subscriptions.push(
      this.menuService.urlChange.subscribe(() => {
        this.menuState = false;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    this.setActiveParent();
  }

  toggleMenu(_event?: any): void {
    Promise.resolve().then(() => {
      this.menuState = !this.menuState;
      this.menuToggled.emit(this.menuState);
    });
  }

  handelCloseMenu() {
    if (this.closeMenu) {
      this.closeMenu.emit(true);
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: { currentTarget: { scrollY: any } }) {
    this.hasScrolled(event.currentTarget.scrollY);
  }

  hasScrolled(scrollY: any) {
    const st = scrollY;

    if (Math.abs(this.lastScrollTop - st) <= this.delta) {
      return;
    }

    this.collapse = st > this.lastScrollTop;
    this.lastScrollTop = st;
  }

  handleMouseOver() {
    this.collapse = false;
  }

  handleMouseEnter() {
    this.collapse = false;
  }

  handleMenuClicked(menu: any) {
    if (this.menuSelected) {
      this.menuSelected.emit(menu);
    }
  }

  showMenu(menu: MenuItem): boolean {
    return menu.show ? menu.show() : true;
  }

  setActiveParent() {
    if (!this.menuItems) {
      return;
    }

    Promise.resolve().then(() => {
      this.homeSelected = true;
      const url = this.menuService.getCurrentUrl();
      this.menuItems.forEach((item) => {
        const regexp = new RegExp(item.url + '.*', 'g');
        item.active = !!(item.children && url.match(regexp));
      });
    });
  }
}
