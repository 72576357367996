import { Component, Input, OnInit } from '@angular/core';
import { CosyImageAngleUrl } from '../../../dtos/CosyImageAngleUrl';
import { SvdsVehicleDetailV4 } from '../../../dtos/SvdsVehicleDetailV4';
import '@neo-ds/components/loader';

@Component({
  selector: 'app-mini-vehicle-details',
  templateUrl: './mini-vehicle-details.component.html',
  styleUrls: ['./mini-vehicle-details.component.scss']
})
export class MiniVehicleDetailsComponent implements OnInit {
  @Input()
  vehicleData: SvdsVehicleDetailV4 | undefined;
  @Input()
  imageUrl: CosyImageAngleUrl | undefined;
  @Input()
  errorMsg: string | undefined | null;

  constructor() {}

  ngOnInit(): void {}
}
