import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { firstValueFrom, Observable, of } from 'rxjs';
import { EnvironmentConfigService } from '../../components/environment-config/environment-config.service';
import { CosyImageAngleUrl } from '../../dtos/CosyImageAngleUrl';
import { SvdsVehicleDetailV4 } from '../../dtos/SvdsVehicleDetailV4';
import { ClearanceDto } from '../../dtos/ClearanceDto';
import { DataService } from '../data.service';
import { ClearanceStatus } from '../../dtos/ClearanceStatus';
import { ErrorParentDto } from '../../dtos/ErrorDto';
import { GcdmDto } from '../../dtos/Gcdm/GcdmDto';
import { AssignmentDto } from '../../dtos/AssignmentDto';
import { AssignmentStatus } from '../../dtos/AssignmentStatus';
import { ViewSource } from '../../dtos/ViewSource';
import { TelematicKeyDto } from '../../dtos/TelematicKeyDto';
import { RedirectStatus } from '../../dtos/RedirectStatus';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
  constructor(
    private httpClient: HttpClient,
    private envService: EnvironmentConfigService,
    private dataService: DataService
  ) {}

  getCosyImage(
    vin: string,
    angle: number,
    width: number
  ): Observable<CosyImageAngleUrl> {
    return this.httpClient.get<CosyImageAngleUrl>(
      `${this.envService.backendUrl}integrations/cosy?vin=${encodeURI(vin)}` +
      `&width=${encodeURI(String(width))}&angle=${encodeURI(String(angle))}`,
      this.authHeaders
    );
  }

  getVehicleData(vin: string, gcid: string): Observable<SvdsVehicleDetailV4> {
    return this.httpClient.get<SvdsVehicleDetailV4>(
      `${this.envService.backendUrl}integrations/vehicle?vin=${encodeURI(
        vin
      )}&gcid=${encodeURI(gcid)}`,
      this.authHeaders
    );
  }

  getClearance(gcid: string): Observable<ClearanceDto | ErrorParentDto> {
    return this.httpClient.get<ClearanceDto | ErrorParentDto>(
      `${
        this.envService.backendUrl
      }integrations/clearance?clearanceId=${encodeURI(
        this.dataService.clearanceId
      )}&gcid=${encodeURI(gcid)}`,
      this.authHeaders
    );
  }

  getAccessTokenForCode(code: string) {
    return firstValueFrom(
      this.httpClient.get<{
        gcid: string;
        token_type: string;
        access_token: string;
        refresh_token: string;
        scope: string;
        expires_in: number;
      }>(
        `${this.envService.backendUrl}integrations/gcdm/code?code=${code}`,
        this.authHeaders
      )
    );
  }

  getAssignment(gcid: string): Observable<AssignmentDto> {
    const id = this._getAssignmentIdQueryParameter(
      this.dataService.assignmentUuid
    );
    return this.httpClient.get<AssignmentDto>(
      `${this.envService.backendUrl}integrations/assignment?${id}&gcid=${encodeURI(gcid)}`,
      this.authHeaders
    );
  }

  getTelematicKeysForLanguage(language: string): Observable<TelematicKeyDto[]> {
    return this.httpClient.get<TelematicKeyDto[]>(
      `${this.envService.backendUrl}integrations/telematicKeys?language=${language}`,
      this.authHeaders
    );
  }

  changeAssignmentStatus(
    vin: string,
    gcid: string,
    status: AssignmentStatus
  ): Observable<any> {
    if (!this.dataService.assignmentUuid) {
      return of(null);
    }
    return this.httpClient.put(
      `${this.envService.backendUrl}integrations/assignment`,
      {
        gcid: gcid,
        id: this.dataService.assignmentUuid,
        status: status,
        vin: vin
      },
      { headers: { Authorization: `Bearer ${this.dataService.accessToken}` } }
    );
  }

  authToken() {
    if (this.envService.isDev) {
      const gcdm: GcdmDto = {
        businessPartner: {
          claims: [],
          communications: { communicationEMails: [] },
          correspondLanguageISO: 'en',
          customExtension: { parameters: [] },
          gcid: '12341234-1234-1234-1234-123412341234',
          givenName: 'Mock-GivenName',
          homeMarket: 'en',
          lastUpdated: { at: new Date(), bySource: 'Dev Mode' },
          partnerCategory: '',
          salutation: 'Mr',
          surname: 'Mock-Surname',
          ucid: '12341234-1234-1234-1234-123412341234',
          vehicles: {
            vehicles: [
              {
                vin: 'ABCDEF01234567890',
                vehicleGUID: '12341234-1234-1234-1234-123412341234',
                vehicleBusinessPartnerRelations: []
              }
            ]
          }
        },
        contactPolicyConsents: [],
        gcid: '12341234-1234-1234-1234-123412341234',
        ucid: '12341234-1234-1234-1234-123412341234',
        userAccount: {
          mail: 'mock@mail.com',
          notificationLevel: '',
          authenticationLevel: '',
          pwPolicyConform: true,
          gcid: '12341234-1234-1234-1234-123412341234',
          secretKnowledgeActivated: true,
          status: ''
        }
      };
      return of([gcdm]);
    } else {
      console.log(`authToken ${this.dataService.accessToken}`);
      let baseUrl = `customer${this.envService.isE2E ? '-i' : ''}.bmwgroup.com`;

      if (this.envService.isUSHub) {
        baseUrl = `login${this.envService.isE2E ? '-i' : ''}.bmwusa.com`;
      }
      return this.httpClient.get<GcdmDto[]>(
        `${this.envService.backendUrl}integrations/gcdm`,
        this.authHeaders
      );
    }
  }

  changeClearanceStatus(
    vin: string,
    gcid: string,
    status: ClearanceStatus
  ): Observable<any> {
    if (!this.dataService.clearanceId) {
      return of(null);
    }
    return this.httpClient.put(
      `${this.envService.backendUrl}integrations/clearance`,
      {
        gcid: gcid,
        clearanceId: this.dataService.clearanceId,
        status: status,
        vin: vin
      },
      this.authHeaders
    );
  }

  get authHeaders() {
    let headers: HttpHeaders = new HttpHeaders();
    if (this.dataService.accessToken) {
      headers = headers.set(
        'Authorization',
        `Bearer ${this.dataService.accessToken}`
      );
    }
    return { headers: headers };
  }

  redirectBackTo(
    id: string | number,
    state:
      | RedirectStatus
      | ClearanceStatus
      | AssignmentStatus
      | string
      | undefined,
    view: ViewSource | undefined
  ) {
    if (this.dataService.redirectUrl) {
      if (view === ViewSource.CLEARANCE) {
        window.location.href = `${this.dataService.redirectUrl}?clearanceId=${id}&state=${state || 'UNKNOWN'}`;
      } else if (view === ViewSource.ASSIGNMENT) {
        window.location.href = `${this.dataService.redirectUrl}?${this._getAssignmentIdQueryParameter(id)}&state=${state || 'UNKNOWN'}`;
      }
    }
  }

  _getAssignmentIdQueryParameter(id: string | number): string {
    return (isNaN(Number(id)) ? 'assignmentUuid=' : 'assignmentId=') + id;
  }
}
