import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from '../models/menu-item';
import { MenuService } from '../../services/menu/menu.service';

/**
 * HeaderComponent contains the main menu items
 */
@Component({
  selector: 'app-header[title]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy, OnInit {
  @Input()
  title: string = '';

  @Input()
  brand: string = '';

  @Input()
  isUsHub: boolean = false;

  /**
   * Toggle menu
   */
  @Output()
  menuToggled = new EventEmitter<boolean>();
  /**
   * Holds the menu state
   */
  menuState: boolean = false;
  /**
   * Flag to indicate if the menu is open
   */
  opened: boolean = false;
  /**
   * Selected menu item
   */
  @Output()
  selectedMenuEvent = new EventEmitter<MenuItem>();
  selectedMenu: MenuItem | undefined;
  /**
   * @ignore
   */
  private subscriptions: Subscription[] = [];

  /**
   * HeaderComponent constructor
   * @param menuService Contains all the menu items to display
   * @param translateService
   */
  constructor(
    public menuService: MenuService,
    private translateService: TranslateService
  ) {
    this.subscriptions.push(
      this.menuService.urlChange.subscribe(() => {
        this.menuState = false;
      })
    );
  }

  ngOnInit(): void {
    // nothing here
  }

  changeLocale(localeID: any) {
    if (localeID) {
      this.translateService.use(localeID);
    }
  }

  /**
   * Unsubscribe from all subscriptions
   */
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Change the state of the menu
   */
  toggleMenu(): void {
    Promise.resolve().then(() => {
      this.menuState = !this.menuState;
      this.menuToggled.emit(this.menuState);
    });
  }

  /**
   * Change the state of the menu
   */
  handleNavigationEventMenu() {
    this.menuState = !this.menuState;
  }

  /**
   * Change the state of the menu
   * @param menu The menu item the user selected
   */
  handleMenuSelected(menu: any) {
    this.selectedMenu = menu;
    this.selectedMenuEvent.emit(menu);
    // @ts-ignore
    this.opened =
      this.selectedMenu?.children && this.selectedMenu.children.length > 0;
  }

  /**
   * Close the menu item
   */
  handleCloseMenuClick() {
    this.opened = false;
  }

  /**
   * Close the menu item
   * @param _menu ignored
   */
  handleDropdownMenu(_menu: any) {
    this.opened = false;
  }

  /**
   * Close the menu item
   */
  handleCloseSideMenu() {
    this.menuState = false;
  }

  log(val: any) {
    console.log(val);
  }
}
