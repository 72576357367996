import { Injectable } from '@angular/core';
import { EnvironmentConfigService } from '../components/environment-config/environment-config.service';
import { DataService } from './data.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private envService: EnvironmentConfigService,
    private dataService: DataService
  ) {}

  startOnePageLogin() {
    let baseUrl = `customer${this.envService.isE2E ? '-i' : ''}.bmwgroup.com`;
    if (this.envService.isUSHub && !this.envService.isDev) {
      baseUrl = `login${this.envService.isE2E ? '-i' : ''}.bmwusa.com`;
    }
    const url =
      `https://${baseUrl}/oneid/#/login` +
      `?client=cardata` +
      `&country=${this.dataService.country}` +
      `&language=${this.dataService.language}` +
      `&brand=${this.dataService.brand}` +
      `&scope=svds%20authenticate_user%20fupo` +
      `&state=abcdef1234` +
      `&response_type=code` +
      `&code_challenge=X1VQxgymjkWckxnmy2p04dImenqkrXixRhh42hQZCZM` +
      `&code_challenge_method=S256` +
      `&redirect_uri=${this.envService.redirectUrl}`;
    console.log(url);
    window.location.href = url;
  }
}
