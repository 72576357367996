import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClearanceDto } from '../../../dtos/ClearanceDto';
import { TelematicKeyDto } from '../../../dtos/TelematicKeyDto';
import { AssignmentDto } from '../../../dtos/AssignmentDto';
import { PackageDetailsDto } from '../../../dtos/PackageDetailsDto';
import { ShowDetailsWrapper } from '../../../dtos/ShowDetailsWrapper';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { IntegrationService } from '../../../services/integration/integration.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-package-details-emea[details]',
  templateUrl: './emea-package-details.component.html',
  styleUrls: ['./emea-package-details.component.scss']
})
export class EmeaPackageDetailsComponent implements OnInit {
  @Input()
  set details(value: PackageDetailsDto | undefined) {
    if (value) {
      this.detailsInfo = value;
      if (value.clearance) {
        this.clearanceInfo = value.clearance;
        if (value.clearance.telematicKeys)
          this.keysFiltered = [...value.clearance.telematicKeys.slice(0, 3)];
      }
      if (value.assignment) {
        this.assignmentInfo = value.assignment;
      }
    }
  }

  @Output()
  showPackageView = new EventEmitter<ShowDetailsWrapper>();

  expanded = false;

  keysFiltered: TelematicKeyDto[] = [];
  clearanceInfo?: ClearanceDto = {
    telematicKeys: []
  } as unknown as ClearanceDto;
  assignmentInfo?: AssignmentDto = {} as AssignmentDto;
  detailsInfo: PackageDetailsDto = {} as PackageDetailsDto;
  @Input() brand!: string;
  @Input() theme!: string;

  constructor(
    public translate: TranslateService,
    private service: IntegrationService
  ) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.expanded) {
        firstValueFrom(
          this.service.getTelematicKeysForLanguage(event.lang)
        ).then((x) => {
          if (this.clearanceInfo) this.clearanceInfo.telematicKeys = x;
          this.keysFiltered = [...x];
        });
      } else {
        firstValueFrom(
          this.service.getTelematicKeysForLanguage(event.lang)
        ).then((x) => {
          if (this.clearanceInfo) this.clearanceInfo.telematicKeys = x;
          this.keysFiltered = [...x.slice()].slice(0, 3);
        });
      }
    });
  }

  hidePackageDetailView() {
    this.showPackageView.emit({
      source: undefined,
      shouldShow: false
    });
  }

  expandDetails() {
    this.expanded = true;
    if (this.clearanceInfo)
      this.keysFiltered =
        this.clearanceInfo.telematicKeys?.length > 0
          ? [...this.clearanceInfo.telematicKeys]
          : [];
  }

  collapseDetails() {
    this.expanded = false;
    if (this.clearanceInfo)
      this.keysFiltered = [...this.clearanceInfo.telematicKeys?.slice(0, 3)];
  }
}
